import type { ClickstreamEvent } from 'app/types';
import { Envelope } from './Envelope';
import { setPropertyInfoBlock } from './contextual-blocks/PropertyInfoBlock';

export const TrackViewHdp = (): ClickstreamEvent => {
  // @ts-expect-error TODO: Need to properly type store
  const state = store.getState();
  const listing = state.currentListingDetails.currentListing;

  const csPayload: ClickstreamEvent = {
    envelope: new Envelope({ eventId: '4785', eventVersion: '2', templateId: '285', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'home_details',
      trigger_source_nm: 'home_details',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };

  if (listing) {
    csPayload.property_info = setPropertyInfoBlock(listing);
  }

  return csPayload;
};

export const TrackViewHomepage = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '5719', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'hp_homepage',
      trigger_source_nm: 'hp_homepage',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};
// JE todo - DONE - SEE @app/shared/pages/MyHubPage/index.js
export const TrackViewMyHub = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6207', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'my_hub_page',
      trigger_source_nm: 'my_hub_page',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};
// JE todo - DONE - SEE @app/shared/pages/SavedSearchPage/index.js
export const TrackViewSearchAlertsPage = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6208', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'search_alerts_page',
      trigger_source_nm: 'search_alerts_page',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};
export const TrackViewLoginPage = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6209', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'login_screen',
      trigger_source_nm: 'login_screen',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};

export const TrackViewUserAccountPage = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6210', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'user_account_page',
      trigger_source_nm: 'user_account_page',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};

export const TrackViewMyListPage = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6211', eventVersion: '2', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'user_item_list_page',
      trigger_source_nm: 'user_item_list_page',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};

export const TrackViewViewedListingsPage = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6212', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'user_item_viewed_page',
      trigger_source_nm: 'user_item_viewed_page',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};

export const TrackViewHiddenListingsPage = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6213', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'user_item_viewed_page',
      trigger_source_nm: 'user_item_viewed_page',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};

// Dave
type SettingsPageTriggers = 'edit_profile_page' | 'renter_profile' | 'edit_settings_page';
export const TrackViewSettingsPage = ({
  triggerLocation,
  triggerSource,
}: {
  triggerLocation: SettingsPageTriggers;
  triggerSource: SettingsPageTriggers;
}): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '6214', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: triggerLocation,
      trigger_source_nm: triggerSource,
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};

export const TrackViewSrp = (): ClickstreamEvent => {
  return {
    envelope: new Envelope({ eventId: '5720', eventVersion: '1', templateId: '171', templateVersion: '1' }),
    clickstream_trigger: {
      trigger_type_nm: 'view',
      trigger_location_nm: 'search_results',
      trigger_source_nm: 'search_results',
      trigger_object_nm: 'no_trigger_object',
    },
    semantic: {
      semantic_event_nm: 'view_content',
    },
  };
};
